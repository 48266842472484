
import { Component, Vue } from "vue-property-decorator";
import { post } from "@/api";

@Component({})
export default class ResetPassword extends Vue {
  key = this.$route.query.key
  password1 = "";
  password2 = "";
  validationError = false;
  validationInvoked = false;

  async updatePassword() {
    if(!this.arePasswordsTheSame){
      this.$toasted.show(this.$t("password-reset.password-match") as string);
      this.validationInvoked = true
      return;
    }
    try {
      await post.resetPassword({
        new_password: this.password1,
        key: this.key
      })
      this.$router.push("/signin")
    } catch (error) {
      console.log(error)
    }
  }

  get arePasswordsTheSame() {
    if (this.password1 === this.password2) {
      return true;
    }
    return false;
  }
}
